<template>
  <div class="video_wrapper" @click="onclick" :class="selected ? 'selected' : ''">
    <video ref="video" autoplay muted playsinline></video>
    <!-- <video autoplay muted playsinline v-else></video> -->
    <!-- <div v-else>未播放</div> -->
    <div class="userInfo">
      {{ stream.user.userName }}
    </div>

  </div>
</template>
<script>
export default {
  name: "VideoPlayer",
  props: {
    stream: {
      type: Object,
      default() { return null }
    }, selected: {
      type: Boolean,
      default() { return false }
    },
  },
  methods: {
    onclick(event) {
      this.$emit("click",)
    }
  },
  data() {
    return {
      userName: null
    }
  },
  watch: {
    stream: {
      immediate: true, // 很重要！！！
      handler(newVal) {
        if (newVal === null || newVal === undefined) {
          return;
        }
        this.$nextTick(() => {
          this.$refs.video.srcObject = newVal.sourceObject;
        })
      }
    }
  }

}
</script>
<style scoped>
.video_wrapper {
  cursor: pointer;
  position: relative;
}


video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: black;

}

.userInfo {
  position: absolute;
    top: 0px;
    /* background-color: black; */
    color: white;
    margin: 5px;
    font-size: small;
    overflow: hidden;
    width: calc(100% - 10px);
    /* padding: 5px; */
    text-wrap: nowrap;
    text-shadow: 1px 1px 1px #000;
    overflow: hidden;
}
</style>