<template>
  <div class="body_bgi full border_box bgs_full">
    <big-screen-header headerName="动物防疫呼叫中心" />

    <div class="call_center_container">
      <div class="left_panel">
        <div class="userListBox">
          <div class="userListBoxTitle">防疫员列表</div>
          <div class="userListTableWrapper">
            <el-table :data="userList" height="calc(100vh - 160px)" ref="userTable" :key="tableUpdateKey">
              <!-- @selection-change="handleSelectionChange" -->
              <el-table-column type="selection"></el-table-column>
              <!-- <el-table-column prop="id" width="40"></el-table-column> -->
              <el-table-column prop="avatar" label="头像" width="80">
                <template slot-scope="scope">
                  <div class="avatar-cell" style="position: relative;">
                    <el-image :src="'https://animalep.5mus.com' + scope.row.avatar"
                      style="height: 40px; width: 40px; background-color: aliceblue;" fit="contain">
                    </el-image>

                    <div class="my-tag">
                      {{ scope.row.id }}
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="昵称">
                <template slot-scope="scope">
                  <span class="ellipsis_text_2">{{ scope.row.name }}</span>
                  <!-- <el-tooltip effect="dark" placement="bottom">sss</el-tooltip> -->
                </template>
              </el-table-column>
              <el-table-column prop="wsOnline" label="在线" width="70">
                <template slot-scope="scope">
                  <el-tag :type="scope.row.wsOnline ? 'success' : 'danger'" size="mini">{{ scope.row.wsOnline ? '在线' :
                    '离线'
                  }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.calling">呼叫中</el-tag>
                  <el-button type="primary" size="mini" @click="tableCallUser(scope)" v-else>呼叫</el-button>
                  <!-- <el-tooltip effect="dark" placement="bottom">sss</el-tooltip> -->
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>

      </div>
      <div style=" width: 65vw;" class="right_panel">
        <div class="videoBox">
          <div id="localVideo">
            <video-player :stream="bigStream" v-if="bigStream !== null" />

            <div id="videoToolbar" class="toolbarBox">
              <el-button size="mini" @click="openChatRoom" v-if="!chatRoomEnabled">开始视频会议</el-button>
              <el-button size="mini" @click="closeChatRoom" v-else>关闭视频会议</el-button>

              <el-button size="mini">视频</el-button>
              <el-button size="mini">音频</el-button>
              <el-button size="mini" @click="clickOpenDeviceDialog()">设备</el-button>
              <el-button size="mini" @click="startScreenSharing()"
                v-if="screenSharingStreamId === null">开启屏幕共享</el-button>
              <el-button size="mini" @click="stopScreenSharing()" v-else>停止屏幕共享</el-button>
              <span class="ml_10 color_orange">房间号：{{ roomId }}</span>
            </div>
          </div>

          <div id="remoteVideo">
            <video-player v-for="stream in localStreamList" :key="stream.streamID" :stream="stream"
              :selected="stream.streamID === selectedStreamId" @click="switchStream(stream)" />

            <video-player v-for="stream in remoteStreamList" :key="stream.streamID" :stream="stream"
              :selected="stream.streamID === selectedStreamId" @click="switchStream(stream)" />
          </div>

        </div>
        <div class="textBox" v-if="textBoxVisible">
          <div id="textBoxToolbar" class="toolbarBox">
            <el-button size="mini" @click="textBoxVisible = false">隐藏</el-button>
          </div>
        </div>
        <div v-else class="textBoxPlaceHolder">
          <div id="textBoxToolbar2" class="toolbarBox">
            <el-button size="mini" @click="textBoxVisible = true">显示</el-button>
          </div>
        </div>
      </div>

      <div class="websocket_state">
        <!-- <div>
          msg:{{ msg }}
        </div> -->
        <div v-if="sock">
          <div class="color_green">状态 {{ wsState }}</div>
        </div>
        <div v-else class="color_red cursor">未连接</div>

        <!-- <el-button @click="sendmsg()">send msg</el-button> -->
      </div>

      <!-- <el-dialog 
        :title="callType == 0 ? '呼叫中...' : '收到呼叫...'" 
        :visible.sync="openCallDialog"
        width="300px"
        class="callerDialog" :close-on-click-modal="false"
      >
        <audio :src="openCallDialog ? require('@/assets/mp3/call.mp3') : ''" autoplay loop></audio>

        <div v-if="false">剩余：{{ callLeftSeconds }}秒</div>

        <div v-if="caller != null">
          <div class="caller_name"> {{ caller.name }} </div>
          <el-image 
            :src="'https://animalep.5mus.com' + caller.avatar"
            style="height: 180px; width: 100%;background-color: aliceblue;" 
            fit="contain">
          </el-image>
        </div>

        <div class="myfooter">
          <div class="call_button bg_green" v-if="callType == 1" @click="answerCall">接听</div>
          <div class="call_button bg_red" @click="hangUpCall">挂断</div>
        </div>
      </el-dialog> -->

      <el-dialog title="设备列表" :visible.sync="openDeviceDialog" width="600px" :close-on-click-modal="false">
        <el-form>
          <el-form-item label="摄像头">
            <el-select v-model="cameraDeviceVal">
              <el-option v-for="item in videoInputList" :key="item.deviceID" :label="item.deviceName"
                :value="item.deviceID"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="麦克风">
            <el-select v-model="microphoneDeviceVal">
              <el-option v-for="item in audioInputList" :key="item.deviceID" :label="item.deviceName"
                :value="item.deviceID"></el-option>
            </el-select>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="openDeviceDialog = false">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import componentsMixin from '@/mixins/components.js'
import ws2 from "@/util/websocket2"
import { getZegoToken, getAntiepidemicPersonListWs } from "@/api/zego/zego.js"
import { ZegoExpressEngine } from 'zego-express-engine-webrtc'
import VideoPlayer from "./VideoPlayer.vue"

export default {
  name: "",
  components: { VideoPlayer },
  mixins: [componentsMixin],
  data() {
    return {
      wsState: null,
      appId: 36758403,
      server: "wss://webliveroom36758403-api.imzego.com/ws",
      msg: "hello",
      token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc1JlZnJlc2giOmZhbHNlLCJpZCI6MTMsImlhdCI6MTcyNTAwNzcyNywiZXhwIjoxNzMyNzgzNzI3fQ._y9dM2TxF2aXvkJqpyoBb7nGCoc23SXA4YDkqjHdzE8",
      sock: window.sessionStorage.getItem('sock'),
      localUser: { userId: "13", userName: "数据中心" },
      // remoteUserList: null,
      openCallDialog: false,
      callType: 0, //0： 主叫 1：被叫
      userList: [],
      roomId: null,
      roomStateReason: null,
      publisherState: null,
      publishQuality: null,
      playerState: null,
      playQuality: {},
      localStreamID: "localstream" + parseInt(Math.random() * 999999) + '',
      remoteStreamID: null,
      cameraCheckStatus: true,
      cameraDeviceVal: null,
      microphoneCheckStatus: true,
      microphoneDeviceVal: null,
      publisherState: null,
      publishQuality: null,
      playerState: null,
      playQuality: {},
      zg: null,
      caller: null,
      remoteStreamList: null,
      localStreamList: null,
      bigStream: null,
      selectedStreamId: null,
      screenSharing: false,
      screenSharingStreamId: null,
      callDialogTimerObject: null,
      callLeftSecondsTotal: 60,
      callLeftSeconds: 0,
      remoteRoomId: null,
      textBoxVisible: true,
      tableUpdateKey: 0,
      openDeviceDialog: false,
      audioInputList: null,
      videoInputList: null,
      wsState: null,
      eventReady: false
    }
  },
  computed: {
    chatRoomEnabled() {
      if (this.roomStateReason === "LOGINED") {
        return true;
        // 登录成功
        //只有当房间状态是登录成功或重连成功时，推流（startPublishingStream）、拉流（startPlayingStream）才能正常收发音视频
        //将自己的音视频流推送到 ZEGO 音视频云

      } else if (this.roomStateReason === "RECONNECTING") {
        return true;
        // 重连中
      } else {
        return false;
      }
    },

    getStateWebSocketCallMsg() {
      return this.$store.state.webSocketCallMsg
    },

    getStateWebSocketCallState() {
      return this.$store.state.webSocketCallState
    },

    getStateAudioInputList() {
      return this.$store.state.audioInputList
    },

    getStateVideoInputList() {
      return this.$store.state.videoInputList
    },

    getStateMicrophoneDeviceVal() {
      return this.$store.state.microphoneDeviceVal
    },

    getStateCameraDeviceVal() {
      return this.$store.state.cameraDeviceVal
    },
  },
  async created() {
    if (zg == null) {
      console.error("zg 未初始化！");
    }
    this.zg = zg;
    await this.checkSystemRequirements();
    // await this.enumDevices();

    // await this.initEvent();

    // ws2.init(
    //   "ws://1.183.3.222:8102/ws", 
    //   this.token, 
    //   data => {
    //     console.log(data);
    //     try {
    //       var msg = JSON.parse(data)

    //       if (msg.topic === "CALL") {
    //         if (this.chatRoomEnabled) {
    //           this.$message.error("收到其他呼叫，来自 " + msg.from.name)
    //         }
    //         else {
    //           // if (this.remoteUserList == null) {
    //           //   this.remoteUserList = [];
    //           // }
    //           // this.remoteUserList.push(msg.from);

    //           if (msg.data && msg.data.roomID) {
    //             this.remoteRoomId = msg.data.roomID;
    //           } else {
    //             this.remoteRoomId = null;
    //           }

    //           if (this.openCallDialog === false) {
    //             this.caller = msg.from;
    //             this.callType = 1;
    //             this.openCallDialog = true;

    //             this.callLeftSeconds = this.callLeftSecondsTotal;

    //             var timer = setInterval(() => {
    //               if (this.callLeftSeconds === 0) {
    //                 timer && clearInterval(timer);
    //               } else {
    //                 this.callLeftSeconds--;
    //               }
    //             }, 1000)

    //             this.callDialogTimerObject && clearTimeout(this.callDialogTimerObject);

    //             this.callDialogTimerObject = setTimeout(() => {
    //               // 60秒后关闭对话框
    //               this.openCallDialog = false;
    //             }, 1000 * this.callLeftSecondsTotal)
    //           }
    //         }
    //       }
    //     } catch (error) {
    //       this.$message.error(error)
    //     }
    //   }, 
    //   state => {
    //     console.log("state changed !");
    //     this.wsState = state;
    //   }
    // )

    // this.sock = ws2.wsObj;

    // 获取防疫员列表
    await this.getAntiepidemicPersonList();
  },
  mounted() {
    this.getStateWebSocketCall()

    this.watchGlobalCall()
  },
  beforeDestroy() {
    // 关闭 房间
    this.closeChatRoom()
  },
  methods: {
    // 监听【全局呼叫】后的【接听】事件
    getStateWebSocketCall() {
      let msg = this.getStateWebSocketCallMsg
      this.wsState = this.getStateWebSocketCallState

      if (msg != null && msg.topic === "CALL") {
        if (this.chatRoomEnabled) {
          this.$message.error("收到其他呼叫，来自 " + msg.from.name)
        } else {
          // if (this.remoteUserList == null) {
          //   this.remoteUserList = [];
          // }
          // this.remoteUserList.push(msg.from);

          if (msg.data && msg.data.roomID) {
            this.remoteRoomId = msg.data.roomID;
          } else {
            this.remoteRoomId = null;
          }

          // 接听事件
          this.answerCall()
        }
      }
    },

    // 监听 App.vue 中的事件
    watchGlobalCall() {
      this.$webSctCall.$on('webSctCall', () => {
        // debugger

        // 从 state 中获取 呼叫的信息
        this.getStateWebSocketCall()
      })
    },

    sendmsg() {
      var msg = {
        wsMessageType: 0,
        from: this.token,
        data: "hello"
      }
      console.log(msg);
      ws2.sendMessage(msg)
    },

    // 获取防疫员列表
    getAntiepidemicPersonList() {
      getAntiepidemicPersonListWs().then(res => {
        // var userList1 = res.data;
        // var userList2 = res.data;
        // this.userList = userList1.concat(userList2);

        this.userList = res.data

        for (let i in this.userList) {
          var element = this.userList[i];
          element.calling = 0;
        }
      })
    },
    wsCallUser(user) {
      // if (this.chatRoomEnabled) {
      //   this.$message.error("正在通话中！");
      //   return

      // }
      // debugger

      // user.callTimer && clearTimeout(user.callTimer)
      // user.callTimer = setTimeout(() => {
      //   user.callTimer = null;
      //   user.calling = 0;
      // }, 3 * 1000);
      // user.calling = 1
      // var userList = this.userList;
      // this.userList = null;
      // this.$nextTick(() => {
      //   this.userList = userList;
      // })

      // for (let index = 0; index < this.userList.length; index++) {
      //   var element = this.userList[index];
      //   if (element.id == user.id) {
      //     this.$set(userList, index, user);
      //   }
      // }

      if (this.wsState === WebSocket.OPEN) {
        this.callType = 0;
        // this.openCallDialog = true;
        this.$notify({
          title: '呼叫',
          message: user.name
          // duration: 10
        })
        // this.remoteUser = user;
        this.caller = user;

        if (this.roomId === null) {
          this.roomId = "0001";//parseInt(Math.random() * 999999) + '';
        }

        var callMsg = {
          wsMessageType: 5,
          topic: "CALL",
          from: this.token,
          to: user.id + "",
          data: {
            roomID: this.roomId
          }
        }
        this.openChatRoom();
        var val = JSON.stringify(callMsg)
        ws2.sendMessage(callMsg);

      } else {
        this.$message.error("websocket未连接！");
      }
    },
    tableCallUser(scope) {
      this.wsCallUser(scope.row);
      var user = scope.row
      user.callTimer && clearTimeout(user.callTimer)
      user.callTimer = setTimeout(() => {
        scope.row.callTimer = null;
        scope.row.calling = 0;
        this.tableUpdateKey = parseInt(Math.random() * 999999) + '';
        this.$set(this.userList, scope.$index, scope.row);
      }, 3 * 1000);
      scope.row.calling = 1;
      this.tableUpdateKey = parseInt(Math.random() * 999999) + '';
      this.$set(this.userList, scope.$index, scope.row);
    },

    calling(scope) {
      if (scope.user.callTimer) {
        return true
      }
      else return false
    },

    hangUpCall() {
      this.openCallDialog = false;
    },

    answerCall() {
      if (this.remoteRoomId) {
        this.roomId = this.remoteRoomId;

        this.openChatRoom()
      }

      this.openCallDialog = false;
    },

    createZegoExpressEngine() {
      const appID = this.appId;
      const server = this.server + ""; //"wss://webliveroom1535963376-api.imzego.com/ws";
      console.log("appId:", appID);
      console.log("server:", server);
      this.zg = new ZegoExpressEngine(appID, server);
      // this.zg.setDebugVerbose(true);
      window.zg = this.zg;
    },

    // Step 1 Check system requirements
    async checkSystemRequirements() {
      console.log('sdk version is', zg.getVersion());

      try {
        const result = await zg.checkSystemRequirements();

        console.warn('checkSystemRequirements ', result);

        if (!result.webRTC) {
          this.$message.error('浏览器不支持webrtc!!');
          return false;
        } else if (!result.videoCodec.H264 && !result.videoCodec.VP8) {
          this.$message.error('浏览器不支持 H264, VP8');
          return false;
        } else if (!result.camera && !result.microphone) {
          this.$message.error('摄像头和麦克风不可用');
          return false;
        } else if (result.videoCodec.VP8) {
          if (!result.screenSharing) this.$message.warn('browser is not support screenSharing');
        } else {
          this.$message.error('不支持VP8，请前往混流转码测试');
        }
        return true;
      } catch (err) {
        this.$message.error('系统检测错误： ' + err);
        return false;
      }
    },

    // 从 state 中获取 设备列表
    getDeviceList() {
      this.audioInputList = this.getStateAudioInputList
      this.videoInputList = this.getStateVideoInputList
      this.microphoneDeviceVal = this.getStateMicrophoneDeviceVal
      this.cameraDeviceVal = this.getStateCameraDeviceVal
    },

    async enumDevices() {
      const audioInputList = [],
        videoInputList = [];

      const deviceInfo = await this.zg.enumDevices();

      deviceInfo &&
        deviceInfo.microphones.map((item, index) => {
          if (!item.deviceName) {
            item.deviceName = 'microphone' + index;
          }
          // audioInputList.push(' <option value="' + item.deviceID + '">' + item.deviceName + '</option>');
          audioInputList.push(item);
          console.log('microphone: ' + item.deviceName);
          return item;
        });

      deviceInfo &&
        deviceInfo.cameras.map((item, index) => {
          if (!item.deviceName) {
            item.deviceName = 'camera' + index;
          }
          // videoInputList.push(' <option value="' + item.deviceID + '">' + item.deviceName + '</option>');
          videoInputList.push(item);
          console.log('camera: ' + item.deviceName);
          return item;
        });

      // audioInputList.push('<option value="0">禁止</option>');
      // videoInputList.push('<option value="0">禁止</option>');
      this.audioInputList = audioInputList;
      this.videoInputList = videoInputList;
      this.microphoneDeviceVal = audioInputList[0].deviceID;
      this.cameraDeviceVal = videoInputList[0].deviceID;

      // $('#MirrorDevices').html(audioInputList.join(''));
      // $('#CameraDevices').html(videoInputList.join(''));
    },

    initEvent() {
      if (zg == null) {
        console.error("zego 没有初始化");
      }
      // 房间状态更新回调
      zg.on('roomStateChanged', (roomID, reason, errorCode, extendData) => {
        var map = {
          "Logining": "登录中",
          "Logined": "已登录",
          "LoginFailed": "登录失败",
          "Reconnecting": "正在重连",
          "Reconnected": "重连成功",
          "ReconnectFailed": "重连失败",
          "Kickout": "被踢出房间",
          "Logout": "已退出",
          "LogoutFailed": "退出失败",
        }

        this.roomStateReason = reason

        // debugger

        if (reason == "KICKOUT") {
          this.$message.error("您被踢出房间")
        }
      });

      // zg.on('roomStateUpdate', (roomId, state) => {
      // });
      zg.on('publisherStateUpdate', (state) => {
        this.publisherState = state
      });
      zg.on('playerStateUpdate', (state) => {
        this.playerState = state;
      })
      zg.on('playQualityUpdate', (streamId, stats) => {
        console.log('playQualityUpdate', streamId, stats);
      })
      zg.on('publishQualityUpdate', (streamId, stats) => {
        console.log('publishQualityUpdate', streamId, stats);
      })
      zg.on('roomStreamUpdate', async (roomId, updateType, streamList, extendedData) => {

        // streams added
        if (updateType === 'ADD') {
          if (this.remoteStreamList === null) {
            this.remoteStreamList = []
          }
          for (let index = 0; index < streamList.length; index++) {
            // const element = array[index];
            const addStream = streamList[index]
            if (addStream && addStream.streamID) {
              if (addStream.streamID === this.localStreamID) {
                continue
              }
              // play the last stream
              // if (this.remoteStreamID) {
              //    this.zg.stopPlayingStream(this.remoteStreamID)
              // }
              // this.remoteStreamID = addStream.streamID
              //$('#PlayUserID').text(addStream.user.userID)

              // debugger
              // try {
              //   remoteStream = await this.zg.startPlayingStream(addStream.streamID)
              // } catch (error) {
              //   console.log(error);
              // }


              if (this.zg.getVersion() < "2.17.0") {
                // $('#playVideo').srcObject = remoteStream;
                // playVideoEl.show();
                // $('#playVideo').show()
                // $('#remoteVideo').hide()
              } else {
                var remoteStream = await this.zg.startPlayingStream(addStream.streamID)
                // const remoteView = this.zg.createRemoteStreamView(remoteStream);
                // remoteView.play("remoteVideo", {
                //   objectFit: "contain",
                //   enableAutoplayDialog: true,
                // })
                // addStream["videoSource"] = `'<source src="${remoteStream}" >'`
                addStream["sourceObject"] = remoteStream;

                this.remoteStreamList.push(addStream)
              }
            }
          }
        } else if (updateType === 'DELETE') {
          //  del stream
          for (let index = 0; index < streamList.length; index++) {
            const delStream = streamList[index];

            if (delStream && delStream.streamID) {
              // if (delStream.streamID === this.remoteStreamID) {
              if (this.zg) {
                this.zg.stopPlayingStream(delStream.streamID)
                this.remoteStreamList.forEach(element => {
                  if (element.streamID === delStream.streamID) {
                    element['delete'] = true
                  }
                });
                // this.remoteStreamList
                // this.remoteStreamID = null
                // playVideoEl.hide();
              }
              // }
            }
          }

          this.remoteStreamList = this.remoteStreamList.filter((item) => {
            return !item['delete']
          })
        }
      });

      this.zg.on('roomUserUpdate', (roomId, updateType, userList) => {
        if (updateType === 'ADD') { 	// users added
          for (let i = 0; i < userList.length; i++) {
            this.userList.push(userList[i]);
            // palyedObj[userList[i].userID] = false;
            // add play cell to playList and add item to userList
            // appendHtml(null, userList[i]);
          }
        }
        else if (updateType === 'DELETE') {
          console.log('删除呼叫！！！！！！！！！！！！');
        } else {
          console.log('其他.................');
        }
      });
      this.eventReady = true;
    },

    async startPublishingStream(streamId, config) {
      try {
        var videoCodec = "VP8";
        var localStreamObject = await this.zg.createStream(config);
        if (config.hasOwnProperty("screen")) {

        }
        var temp = this.zg.startPublishingStream(streamId, localStreamObject, { videoCodec });
        if (this.zg.getVersion() < "2.17.0") {
          this.$refs['publishVideo'].srcObject = this.localStreamObject;
          // $('#publishVideo')[0].srcObject = localStream;
          // $('#publishVideo').show()
          // $('#localVideo').hide()
        } else {
          var localStream = {
            streamID: streamId,
            user: {
              userID: this.localUser.userId,
              userName: this.localUser.userName
            },
            sourceObject: localStreamObject
          }
          if (this.localStreamList == null) {
            this.localStreamList = []
          }
          this.localStreamList.push(localStream);
          this.bigStream = localStream;
          // const localView = this.zg.createLocalStreamView(this.localStreamObject);
          // localView.play("localVideo", {
          //   mirror: true,
          //   // objectFit: "cover",
          //   enableAutoplayDialog: true,
          // })
          // $('#publishVideo').hide()
          // $('#localVideo').show()
        }
        return true;
      } catch (err) {
        return false;
      }
    },

    async startScreenSharing() {
      var streamId = "ScreenShare-" + parseInt(Math.random() * 999999) + '';
      const config = {
        videoBitrate: {
          bitrate: 3000,
          startBitrate: 'target'
        },
        screen: {
          audio: true,
          video: {
            frameRate: 20,
            width: 1080,
            height: 720,
            quality: 4,
          }
        }
      };
      this.screenSharing = await this.startPublishingStream(streamId, config)
      this.screenSharingStreamId = streamId;
    },

    stopScreenSharing() {
      if (this.screenSharingStreamId == null)
        return
      this.zg.stopPublishingStream(this.screenSharingStreamId)
      this.zg.stopPlayingStream(this.screenSharingStreamId);
      // this.screenSharingStreamId = null;
      // this.localStreamList
      for (let index = 0; index < this.localStreamList.length; index++) {
        const stream = this.localStreamList[index];
        if (stream.streamID === this.screenSharingStreamId) {
          stream["delete"] = true;
        }
      }
      this.localStreamList = this.localStreamList.filter((item) => {
        return !item['delete']
      })
      if (this.bigStream.streamID === this.screenSharingStreamId && this.localStreamList && this.localStreamList.length > 0) {
        this.bigStream = this.localStreamList[0];
      }
      this.screenSharingStreamId = null;
    },

    async loginRoom(roomId, userId, userName, token) {

      // Need to get the token before logging in to the room
      // localStorage.setItem('roomID', roomID);
      // localStorage.setItem("userID", userID);
      // localStorage.setItem("token", token);
      try {
        return await this.zg.loginRoom(roomId + "", token, {
          userID: userId,
          userName: userName
        }, { userUpdate: true });
      } catch (err) {
        this.$message.error(JSON.stringify(err));
        return false;
      }
    },

    async openChatRoom() {
      await this.getDeviceList();
      if (this.chatRoomEnabled) {
        return;
      }

      if (this.roomId === null) {
        this.roomId = "0001";//parseInt(Math.random() * 999999) + '';
      }

      if (!this.eventReady) {
        this.initEvent();
      }

      const self = this;
      const roomId = this.roomId;
      const userId = this.localUser.userId;
      const userName = this.localUser.userName;

      var res = await getZegoToken(userId);

      const token = res.data;
    
      try {
        var loginResult = await self.loginRoom(roomId, userId, userName, token);
        // console.log('xxxxxxxxxxxxxxxxxxxx : ', self.localStreamID)
        await self.startPublishingStream(self.localStreamID, {
          camera: {
            video: self.cameraCheckStatus ? {
              input: self.cameraDeviceVal.deviceID
            } : false,
            audio: self.microphoneCheckStatus ? {
              input: self.microphoneDeviceVal.deviceID
            } : false,
          }
        });

        self.selectedStreamId = self.localStreamID;
      }
      catch (err) {
        console.log(err)
      }


      // var promise = new Promise(async (resolve, reject) => {
      //   getZegoToken(userId).then(async res => {
      //     const token = res.data;
      //     try {
      //       var loginResult = await self.loginRoom(roomId, userId, userName, token);

      //       console.log(loginResult);
      //       resolve(loginResult);
      //     }
      //     catch (err) {
      //       reject(-1000)
      //     }

      //   });
      // })
      // promise.then(async (result) => {
      //   await self.startPublishingStream(self.localStreamID, {
      //     camera: {
      //       video: self.cameraCheckStatus ? {
      //         input: self.cameraDeviceVal.deviceID
      //       } : false,
      //       audio: self.microphoneCheckStatus ? {
      //         input: self.microphoneDeviceVal.deviceID
      //       } : false,
      //     }
      //   });
      //   self.selectedStreamId = self.localStreamID;
      // })
      // getZegoToken("13").then(async res => {

      //   // const token = res.data.token;
      //   const token = res.data;

      //   await self.loginRoom(self.roomId, "13", "数据中心", token);
      //   // await self.startPublishingStream(self.localStreamID, {
      //   //   camera: {
      //   //     video: self.cameraCheckStatus ? {
      //   //       input: self.cameraDeviceVal
      //   //     } : false,
      //   //     audio: self.microphoneCheckStatus ? {
      //   //       input: self.microphoneDeviceVal
      //   //     } : false,
      //   //   }
      //   // });
      // });

    },

    closeChatRoom() {
      this.closeSession();
      this.roomId = null;
    },

    logoutRoom() {
      // this.remoteStreamID && this.zg.stopPlayingStream(this.remoteStreamID);
      // this.localStreamID && this.zg.stopPlayingStream(this.localStreamID);

      var result = this.zg.logoutRoom(this.roomId);
      // this.clearStream();
    },

    clearStream() {
      if (this.localStreamList && this.localStreamList.length > 0) {
        for (let index = 0; index < this.localStreamList.length; index++) {
          const element = this.localStreamList[index];
          element.sourceObject && this.zg.destroyStream(element.sourceObject);
        }
      }
      if (this.remoteStreamList !== null) {
        for (let index = 0; index < this.remoteStreamList.length; index++) {
          const remoteStream = this.remoteStreamList[index];
          remoteStream && this.zg.destroyStream(remoteStream.sourceObject);
        }
      }
      // this.$refs['publishVideo'].srcObject = this.localStreamObject;
      // $('#publishVideo')[0].srcObject = null;
      // $('#playVideo')[0].srcObject = null;
      this.localStreamList = null;
      this.remoteStreamList = null;
      this.screenSharingStreamId = null;
    },

    closeSession() {
      this.clearStream();
      this.logoutRoom();
    },

    switchStream(stream) {
      this.selectedStreamId = stream.streamID;
      this.bigStream = stream;
    },
    clickOpenDeviceDialog() {
      this.enumDevices();
      this.openDeviceDialog = true;
    }
  }
}
</script>

<style scoped lang="less">
.myfooter {
  display: flex;
  justify-content: space-around;
  margin: 20px;

  .call_button {
    width: 50px;
    height: 50px;
    background-color: red;
    border-radius: 50%;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
    border: 10px black;
    // box-shadow: 1px 1px 1px black;
  }

  .call_button:hover {
    box-shadow: 2px 2px 4px #402222;
  }

  .call_button:active {
    box-shadow: inset 0px -1px 4px 0px #0000003d;
  }

  .bg_red {
    background-color: red;
  }

  .bg_green {
    background-color: rgb(27, 193, 27);
  }
}

.caller_name {
  text-align: center;
  height: 40px;
  list-style: 40px;
  font-size: x-large;
}

.call_center_container {
  // position: relative;
  // background-color: black;
  margin: auto;
  height: calc(100% - 50px);
  width: 100%;
  display: flex;
  gap: 50px;
  padding: 10px;
  // justify-content: space-around

}

.websocket_state {
  position: absolute;
  top: 5px;
  right: 20px;
  padding: 10px;
  // background-color: red;
}

.avatar-cell {
  div {
    display: inline-block;
  }
}

.my-tag {
  position: absolute;
  /* bottom: -20px; */
  /* right: 0; */
  top: 18px;
  left: -10px;
  height: 20px;
  padding: 0 5px;
  z-index: 10;
  background-color: #f3f3f3;
  border: solid 1px #5d5454;
  border-radius: 5px;
  text-align: center;
  line-height: 20px;
  color: #645f5f;
  box-shadow: 1px 1px 2px 2px rgba(180, 165, 165, 0.507);
}

.el-table,
.el-table__expanded-cell {
  background-color: rgb(56, 56, 47);
  // background-color: transparent;//这是设置透明背景色
}

div.toolbarBox {
  display: block;
  position: absolute;
  bottom: 0;
  height: 40px;
  background-color: rgba(81, 31, 31, 0.553);
  width: 100%;
  margin: auto;
  padding: 5px;
  border-radius: 5px;
}

.left_panel {
  position: relative;

  .userListBox {
    background-color: white;
    border-radius: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .userListBoxTitle {
      height: 50px;
      // background-color: red;
      line-height: 50px;
      font-size: large;
      padding-left: 10px;
    }
  }

  .userListTableWrapper {
    padding: 5px;
    background-color: rgb(253, 253, 255);
    border-radius: 10px;
  }
}

.right_panel {
  display: flex;
  flex-direction: column;

  gap: 10px;
  flex-grow: 1;
  align-items: stretch;
  flex-wrap: nowrap;

  .videoBox:hover {
    #videoToolbar {
      display: block
    }
  }

  .videoBox {
    position: relative;
    background-color: black;
    border-radius: 10px;
    border: solid 5px white;
    /* height: 50vh; */
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    height: 100%;
    width: 100%;

    #localVideo {
      background-color: #5d5454;
      height: 100%;
      position: relative;
      flex-grow: 1;
      border-radius: 5px 0 0 5px;

      .video_wrapper {
        position: absolute;
        height: 100%;
        width: 100%;
      }
    }

    #remoteVideo {
      // position: absolute;
      height: 100%;
      width: 200px;
      background-color: #606266;
      top: 0;
      right: 0;
      overflow-y: auto;
      overflow-x: hidden;
      border-left: solid 5px white;
      flex-shrink: 1;
      border-radius: 0 5px 5px 0;

      // box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.312);
      div.selected {
        background-color: white;
        border: solid 5px rgb(204, 38, 38);
      }

      .video_wrapper {
        // position: absolute;
        height: 100%;
        max-height: 150px;
        width: 100%;
        background-color: #5d5454;
        border-bottom: solid 2px rgba(204, 38, 38, 0.268);

        video {
          height: 100px;
          max-height: 30px;
        }
      }

    }

    #remoteVideo2 {
      width: 200px;
    }

  }

  .textBox {
    background-color: #8150503d;
    height: 100%;
    width: 100%;
    border: solid 5px white;
    border-radius: 10px;
    position: relative;
  }

  .textBoxPlaceHolder {
    height: 100%;
    width: 100%;
    border: solid 5px white;
    border-radius: 10px;
    position: relative;
    height: 55px;
  }

  .textBox:hover {

    #textBoxToolbar {
      display: block;
    }
  }
}
</style>

<style lang="less">
.callerDialog {
  .el-dialog__body {
    padding: 5px;
  }
}
</style>