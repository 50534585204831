import request from '@/util/request'

// zego 获取token 
export function getZegoToken(userId) {
  return request({
      url: `/app/zego/token/getToken?userId=` + userId,
      method: 'get'
  })
}

// 获取 防疫呼叫页面的 防疫员列表
export function getAntiepidemicPersonListWs(townId) {
  // var query = townId === null || townId === undefined? '?page=0&size=1000' : `?page=0&size=1000`
  return request({
      url: `/app/animal/bigData/getAntiepidemicPersonListWs?page=0&size=1000`,
      method: 'get'
  })
}